i frame#myId {
  position: absolute;
  height: 100%;
  width: 100%;
}

.basic-multi-select {
  width: 100% !important;
}

/* dropdown css starts */

.dropdown-item{
  padding: 15px 10px !important
}

.custom-dropdown-menu {
  max-height: 400px !important; /* Adjust the height as needed */
  overflow-y: auto !important;
}

/* .dropdown .btn-secondary{
  outline: none !important;
  border: none !important;
  background-color:#00BCD4 !important;
  font-size: 12px !important;
} */

.btn_management > .btn-secondary{
  outline: none !important;
  border: none !important;
  background-color:#00BCD4 !important;
  font-size: 12px !important;
  margin-left: 10px !important;
}


.dropdown-item:hover, .dropdown-item:focus{
  color: #ffffff;
  text-decoration: none;
  background-color: #00bcd4;
}

/* dropdown css ends */


.css-bg1rzq-control{
    box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.12) !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #54667a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

h3#operations-tag-default{
  display: none !important;
}

.disabled-option {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(228, 226, 226);
}

.app-search .srh-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 0px;
  height: 0px;
  cursor: pointer;
  font-size: 14px;
  min-width: 40px;
  min-height: 36px;
  background-color: #02bcd4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: solid 0.5px rgba(0, 0, 0, 0.24);
  /* margin-left: -25px; */
  margin-top: 8px;
  line-height: 25px;
}

.app-search {
  margin: 8px;
  margin-right: 100px;
}

.sweet-alert h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

button.btn.btn-lg.btn-default {
  border: 2px solid #0000002b;
}

#save-button {
  position: absolute;
}

.delete {
  position: relative;

}

.delete:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: max-content;
  background-color: rgb(10, 10, 10);
  color: white;
  font-weight: 300;
  text-align: center;
  padding: 5px 5px;
  position: absolute;
  /* z-index: 1; */
  right: 0;
  left: -15;
  bottom: 150%;
}

.delete:hover:before {
  opacity: 1;
  visibility: visible;
}

.swagger-ui section.models.is-open {
  padding: 0 0 20px;
  display: none;
}

.swagger-ui .scheme-container {
  background: #f2f7f8 !important;
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 0%) !important;
  margin: 0 0 0px 20px !important;
  padding: 0px 0 !important;
}

.custom-backdrop {
  background-color: rgb(5, 4, 4) !important;
}

textarea.textarea-api.form-control {
  height: 150px;
}

span.copy-clip {
  /* float: right; */
  margin-left: 5px;
  font-size: 12px;
  color: #76889c;
  font-weight: 500;
  /* cursor: copy; */
}

.swagger-ui input[disabled],
.swagger-ui .parameters-col_description input[type="text"] {
  display: none;
}

.swagger-ui .opblock.opblock-post .opblock-summary-method {
  background: #00bcd4;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  margin-top: 10px !important;
  color: #666565;
  font-weight: normal !important;
  font-size: 0.944rem;
  margin-bottom: 15px !important;
}

.dropdown-toggle::after{
  margin-left:1rem !important;
}

.css-bgvzuu-indicatorSeparator{
  display: none !important;
}

.css-16pqwjk-indicatorContainer{
  width: 30px !important;
  color: #54667a !important;
  padding:0px !important;
  /* border-left: 1px solid gray; */
}
svg.css-19bqh2r{
  margin-left: 14px !important;
}
.react-datepicker__view-calendar-icon input{
    border-radius: 5px;
    padding: 6px 10px 5px 25px;
    outline: none !important;
    border: none !important;
}

#pr_id_1 > ul {
  margin-bottom:0px !important;
}
/* ===========sonarqube issue== duplicate */
/* 
.p-icon,.p-autocomplete-token-icon{
  color: #000 !important;
} */

.p-autocomplete-token-label{
  color: black !important;
}

.css-11hh5z3 {
  font-size:80% I !important;
}

.css-17mx7ar-multiValue {
  min-width: fit-content !important;
}

.css-1l3ls32{
  cursor:  pointer !important;
  padding-left: 2px !important;
}

.image-for-50 {
  background-image: url('./assets/images/icon/exclamation.png');
  width: 16px;
  height: 16px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}

.image-for-100 {
  background-image: url('./assets/images/icon/double_check.png');
  width: 19px;
  height: 10px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  zoom:1;
  margin-left: -3px !important;
}

.fa-check{
  font-size: 15px !important;
}

.react-datepicker__close-icon{
  top:2px !important;
}




.react-datepicker__input-container > input{
  border-radius: 0.25rem !important;
  height: 1.6rem !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  margin-top: 5px !important;
  width: 14rem !important;
  
}

.react-datepicker__input-container{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

div.react-datepicker__tab-loop > div.react-datepicker__portal > div > div > div.react-datepicker__month-container > div.react-datepicker__header > div.react-datepicker__current-month{
  font-size: 18px !important;
}

.country_select_dropdown{
  /* display: block !important; */
  border-radius: 0.2rem !important;
  width: 100% !important;
  min-height: 1.6rem !important;
  align-items: center;
  display: flex !important;
  margin-top: 10px;
  overflow: auto !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* overflow: overlay; */
  /* overflow-x: scroll; */
}

.p-autocomplete-loader {
  /* display: none !important; */
  margin-left: 5px !important;
}

.p-autocomplete-input-token{
    margin-left:10px !important;
}
.p-autocomplete-input-token > input {
  width: 10rem !important; 
  margin-left: 0.7rem !important;
}

.country_select_dropdown > span {
  padding-right: 0% !important;
  height: 40px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  max-width: 230px !important;
}


.p-autocomplete-item{
    color: grey !important;
    font-family: sans-serif;
    margin-left: 5px !important;
    padding-left: 0.3rem !important;
    font-size: 15px !important;
    border-radius: 5px;
    padding-right: 0.3rem;
    margin-right: 0.3rem !important;
    margin-top: 2px !important;
    cursor: pointer;
}

.p-autocomplete-items-wrapper {
  background-color: #faffff !important;
  margin-top: 0.3rem !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border-radius: 3px !important;
}

.p-autocomplete-items{
  margin-top: 5px;
  margin-bottom: 5px;
}



.p-autocomplete-item:hover{
  background-color: #9cbab33b;
}

.p-autocomplete-item.active {
  background-color: rgba(0, 123, 255, 0.8); /* Blue background */
  color: white; /* Optional: Change text color to white for contrast */
}

.p-autocomplete .p-component .p-inputwrapper .p-autocomplete-multiple .p-inputwrapper-filled {
  margin-top: 0px !important;
}


.p-autocomplete-multiple-container,.p-component, .p-inputtext{
  margin-bottom:2px !important;
  margin-top: 2px !important;
}


.p-autocomplete-token,.p-highlight{
  margin-left: 5px !important;
  background: #9cbab33b !important;
  padding-left: 0.3rem !important;
  font-size: 15px !important;
  border-radius: 5px;
  padding-right: 0.3rem;
  margin-right: 0.3rem !important;
  margin-top: 2px !important;
}

.p-autocomplete-token-label{
  margin-right: 3px !important;
  color: #6b6b6b !important;
  font-weight: 400 !important;
}

.p-icon, .p-autocomplete-token-icon{
  color: #6b6b6b !important;
}



span.beta-login {
  color: #808080;
  position: absolute;
  font-size: 16px;
  margin-top: -4px;
  font-weight: 400;
}

.swagger-ui .loading-container {
  align-items: center;
  display: flex;
}

.swagger-ui .loading-container .loading {
  position: relative;
  left: 0%;
}

/* @media (min-width: 767px) {
/* .authentications {
   overflow: hidden;
   /* position: absolute; 
} 
} */

i.mdi.mdi-refresh {
  color: white;
}



.scroll-sidebar {
  position: relative;
  height: calc(100% - 4%);
}

@media (max-width: 991.98px) {
  .api-refrence-footer {
    width: 100%;
    margin-left: 0px;
  }

  .topbar .top-navbar .navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20%;
  }

  img.logo-home {
    margin-top: -45px;
  }

  span.beta-login {
    margin-top: -35px;
  }
}

/* @media (min-width: 768px)
{
   #main-wrapper[data-layout="vertical"][data-sidebartype="full"] .topbar .top-navbar .navbar-header {
      width: 190px;
      margin-left: 1%;
   }
} */

.page-wrapper > .page-content {
  padding: 20px;
  margin-top: -20px;
  /* min-height: calc(100vh - 25px); */
  min-height: 85vh;
}

.table {
  margin-bottom: 0;
}

.sidebar-search-btn {
  padding: 5px 5px;
}

i.fas.fa-eye {
  color: rgb(255, 255, 255);
}

input.search-input.form-control {
  width: 75%;
  height: calc(1em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}

button.search-button {
  height: calc(1em + 0.75rem + 2px);
  padding: 5px 10px;
}

.search-form.input-group {
  width: 100%;
}

.iframe-button {
  height: 85vh;
}

.nav-item > .nav-link {
  display: flex;
  align-items: center;
}

.previewImg1 {
  background: url("assets/images/preview-img1.png");
  height: 450px;
  background-size: contain;
  background-repeat: no-repeat;

  background-position: center;
  position: relative;
}

.previewImg2 {
  background: url("assets/images/preview-img2.png");
  height: 450px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}

.previewImg3 {
  background: url("assets/images/preview-img3.png");
  height: 450px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}

.swagger-ui .opblock-summary-control:focus{
  outline: none !important;
}

.logo1-container {
  display: flex;
  align-items: center;
  width: 260px;
  height: 60px;
  position: absolute;
  top: 5%;
  left: 47%;
  transform: translate(-50%, 0);
}

.preview-logo {
  max-height: 60px;
  max-width: 310px;
}

.preview-logo2 {
  object-fit: cover;
  height: 100%;
  width: 100%;
  display: block;
}

.preview2-header {
  position: absolute;
  right: 0;
  background: #000;
  max-width: 400px;
  height: 40px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 2px;
  padding-left: 10px;
  text-align: center;
}

.preview2-header h5 {
  color: white;
  margin: 0;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 400;
}

.preview-header-cross img {
  height: 20px;
  margin-left: 10px;
  margin-right: 7px;
}

.logo2-container {
  padding: 1px;
  max-width: 200px;
  height: 38px;
  background: white;
  margin-left: 20px;
  object-fit: cover;
  border-radius: 2px;
}

.logo3-container {
  display: flex;
  align-items: center;
  width: 160px;
  height: 40px;
  border-radius: 2px;
  top: 24px;
  right: 12%;
  transform: translate(50%);
  position: absolute;
}

.preview-logo3 {
  max-height: 35px;
  max-width: 150px;
}

.previewImg3 img {
  margin: 0;
}

.spinner {
  height: 300px !important;
  width: 300px !important;
}

.modal-title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 30px;
}

.btn-nav {
  background-color: #0000;
}

.btn-nav:focus {
  background-color: rgb(0, 188, 212);
}

@media screen and (min-width: 1080px) and (max-width: 1900px) {
  .iframe-button {
    height: 550px;
  }
}

.modal-dialog.undefinedlogs-modal .modal-content {
  width: 750px;
}

.modal-preview {
  padding: 0;
}

@media (max-width: 700px) {
  .preview-logo {
    left: 32%;
  }

  .logo3-container {
    display: flex;
    align-items: center;
    width: 140px;
    height: 30px;

    top: 20px;
    right: 12%;
  }

  .preview-logo3 {
    max-height: 30px;
  }
}

@media (max-width: 600px) {
  .preview-logo3 {
    max-width: 140px;
    max-height: 25px;
    padding: 0 15px;
  }

  .logo3-container {
    display: flex;
    align-items: center;
    width: 140px;
    height: 30px;

    top: 15px;
    right: 12%;
  }
}

@media (max-width: 500px) {
  .logo1-container {
    width: 200px;
    height: 40px;
    position: absolute;
    top: 12%;
    left: 40%;
    transform: translate(-50%, 0);
  }

  .preview-logo {
    top: 10%;
    left: 18%;
  }

  .modal-preview {
    width: 320px;
  }

  .preview2-header {
    max-width: 300px;
    height: 28px;
    padding-left: 10px;
  }

  .logo2-container {
    max-width: 160px;
    height: 26px;
  }

  .preview-logo3 {
    max-width: 100px;
    max-height: 20px;
    padding: 0;
    top: 5px;
  }

  .logo3-container {
    width: 70px;
    height: 25px;
    top: 3px;
    right: 10%;
  }
}

#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link.link-disabled {
  color: #a9a3a3;
  opacity: 1;
  pointer-events: "none";
}

.topbar .top-navbar .navbar-header .navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  margin: 0;
}

.topbar {
  position: relative;
  height: 50px;
}

.search-bar {
  position: absolute;
  right: 0;
  border-radius: 4px;
  height: 28px;
  width: 45px;
  opacity: 0.55;
  background-color: #2dc5e0;
}

button.search-button.btn.btn-secondary {
  border-color: black;
  background-color: #02bcd4;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.search-button.btn.btn-warning {
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.search-button.search-warning.btn.btn-warning {
  border-color: black;
}

.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
  line-height: 10px;
  height: 40px;
  padding: 3px 0.75rem;
  font-size: 14px;
}



.topbar .top-navbar {
  min-height: 50px;
  padding: 0;
}

/* .navbar-nav {
	margin-top: -20px;
} */
.table-responsive {
	overflow-x: visible;
}
.table-tr {
  /* text-align: center; */

  line-height: 0;
  /* text-align: center; */
}

.theme-color .theme-item .theme-link[data-navbarbg="skin1"],
#main-wrapper[data-layout="vertical"]
  .topbar
  .navbar-collapse[data-navbarbg="skin1"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin1"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .navbar-collapse[data-navbarbg="skin1"],
#main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin1"] {
  background: white;
  box-shadow: 0px 0 10px 1px rgb(0 0 0 / 8%);
}

.nav_btn_container{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
}

@media (min-width:992px) and (max-width:1370.8px){
  #nav-btn-updatesandbox{
    margin-top: 6px !important;
    margin-bottom: 1% !important;
    margin-left: 5px !important;
  }
}


@media (max-width: 991.98px) {
  .topbar .top-navbar .navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 0%;
  }

  #nav-btn-updatesandbox{
    margin-left: 0.5% !important;
    margin-bottom: 1% !important;
  }

  .topbar .nav-toggler,
  .topbar .topbartoggler {
    color: #fff;
    padding: 0px 20px;
  }

  span.logo-text {
    display: none;
  }

  .topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
    /* margin-right: 35px; */
    margin-left: 10px;
    margin-top: -60px;
  }

  span.topbartoggler.d-block.d-md-none.mob-tonggle {
    margin-left: -50px;
  }

  .topbar .top-navbar .navbar-nav {
    flex-direction: row;
    margin-top: -5px;
    padding: 5px;
  }

  .topbar .top-navbar .navbar-collapse.show,
  .topbar .top-navbar .navbar-collapse.collapsing {
    display: block;
    width: 100%;
    border-top: unset;
  }
}

#container_pre{
  background-color: #000 !important;
}
#code_desc{
  background-color: #000 !important;
  color:white;
  margin-left: 2rem !important;
}

#comment_swag_code
{
  color:#18d918 !important;
  margin:0px !important;
}

#heading_swag_code{
  margin-top:-1rem !important;
  /* margin-left:2rem !important; */
}

.parameter__name{
  width: 10rem !important;
}

@media (max-width: 766px) {
  #main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"]
    .topbar
    .top-navbar
    .navbar-collapse,
  #main-wrapper[data-layout="vertical"][data-header-position="fixed"][data-sidebar-position="fixed"]
    .topbar
    .top-navbar
    .navbar-collapse {
    position: relative;
    top: 40px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

.settingIcon-header {
  position: absolute;
  right: 20px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 22px;
}

@media (max-width: 991.98px) {
  .swagger-ui .opblock-tag {
    color: #3b4151;
    font-family: sans-serif;
    font-size: 16px;
    margin: 0 0 5px;
  }

  .topbar .nav-toggler,
  .topbar .topbartoggler {
    color: #fff;
    padding: 0px 0px 0px 100px;
  }

  .settingIcon-header {
    position: absolute;
    right: 80px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 22px;
  }
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  width: 35px;
  height: 35px;
  padding: 3px 8px;
  font-size: 14px;
}

.btn-color-diro {
  background-color: rgb(0, 188, 212) !important;
  border-color: rgb(0, 188, 212);
}

.btn-preview {
  margin-top: -20px;
  padding: 4px 7px;
  font-size: 13px;
  border-color: #0000;
}

.custom-control-input {
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: rgb(0, 188, 212) !important;
  background-color: rgb(0, 188, 212) !important;
  box-shadow: none;
}

.btn-color-diro-outline {
  /* background-color: rgb(0, 188, 212); */
  border-color: rgb(0, 188, 212);
  color: rgb(0, 188, 212);
}

.btn-color-diro-outline:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: rgb(0, 188, 212);
  border-color: rgb(0, 188, 212);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(0, 188, 212);
  border-color: rgb(0, 188, 212);
}

.btn-color-diro-light {
  background-color: #00bcd4b5;
}

.ml-10 {
  margin-left: 35%;
}

button.accordion-button {
  width: 100%;
  text-align: left;
  padding: 4px 7px;
  /* padding-right: 3px; */
  background: white;
  border: 1px solid #e9ecef !important;
  color: #54667a;
  border-radius: 4px;
}

h2.accordion-header {
  font-size: 18px;
  margin-bottom: 0px;
  color: #54667a;
}

.text-muted {
  color: #90a4ae !important;
  margin-top: 0px;
}

.cross-field {
  margin-top: 10px;
}

.card {
  /* padding: 0; */
  border-radius: 9px;
  /* background-color: #f2f7f8; */

  background: rgb(255, 255, 255);
  box-shadow: 0px 0 10px 1px rgb(0 0 0 / 8%);
  border: none;
}

.asterisk {
  color: red;
}

small.cus-temp {
  text-align: right;
  color: #00bcd4 !important;
  font-size: 16px;
}

.template-modal-color {
  background-color: #fff;
}

#modalHeader {
  justify-content: center !important;
  border-bottom: 1px solid #e9ecef !important;
  padding-block-end: 10px !important;
  margin-right: 30px #fbf4e5 !important;
  padding-left: 0% !important;
}

ul.help_box {
  background-color: white;
}

span.button-id-header {
  font-size: 14px;
  margin-top: 22px;
  margin-left: -15px;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.btn-success:hover {
  color: #fff;
  background-color: #00bcd4b5;
  border-color: #00bcd4b5;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4b5;
}

.dropdown {
  display: flex;
  align-items: center;
}

@media (max-width: 991.98px) {
  .topbar .top-navbar .navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: -76px;
    width: 0%;
  }

  .font-weight-medium.rounded-top.py-3.px-4 {
    color: rgb(33, 37, 41);
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 17px;
    display: flex;
    margin-top: 7px !important;
    margin-left: 20px !important;
  }
}

.card .card-title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #2c2b2e;
}

textarea.textarea-reqdoc.form-control {
  height: 150px;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #5ac8da;
  border-color: #5ac8da;
}

.btn-primary:disabled {
  color: #fff;
  background-color: #b5b2c9;
  border-color: #b5b2c9;
}

.btn-primary {
  color: #fff;
  background-color: #00bcd4b5;
  border-color: #00bcd4b5;
  box-shadow: 0 1px 0 rgb(255 255 255 / 15%);
}

.btn-primary:hover {
  color: #fff;
  background-color: #5ac8da;
  border-color: #00bcd4b5;
}

button.btn.btn-diro-tongle-primary.active {
  color: #fff;
  background-color: #5ac8da;
  border-color: #5ac8da;
}

/*  */
.btn-danger {
  background-color: #e96980;
}

.swagger-ui .opblock.opblock-post .opblock-summary-method {
  background: #00bcd4 !important;
}

.swagger-ui .opblock.opblock-post .opblock-summary {
  border-color: #00bcd4 !important;
}

.swagger-ui .opblock.opblock-post {
  background: rgb(0 201 250 / 12%) !important;
  border-color: #00bcd4 !important;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: rgb(0 201 250 / 12%) !important;
}

.btn-success {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.no-data-found {
  text-align: center;
  height: 80vh;
}

.cog-header {
  color: #ffbc34;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  /* color: #fff; */
  font-size: 18px;
  font-weight: 400;
  background-color: rgb(0, 188, 212) !important;
  color: white;
  display: flex;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.nav-link {
  color: black;
  font-size: 18px;
  font-weight: 400;
}

Nav {
  display: flex;
}

.nav-button {
  display: flex;
  /* margin-left: 150px; */
  font-size: 18px;
  /* margin-bottom: 5px; */
  /* border-radius: 10px; */
  /* justify-content: space-between; */
}

.grecaptcha-badge {
  visibility: hidden;
}

#nav-id {
  margin-left: 5px !important;
  height: 30px !important;
  margin-top: 7px !important;
  margin-bottom: 5px !important;
  margin-right: 15px;
}

.custom-select {
  border: 1px solid #000000;
}

.mrt-20 {
  margin-top: -20px;
}

.br-4 {
  border-radius: 4px;
}

.col-red {
  color: #e96980;
  font-size: 20px;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  color: #fff;
  background: #00bcd4 !important;
}

.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-label {
  padding: 3px 12px !important;
  font-size: 12px;
  line-height: 18px !important;
}

/* #operations-tag-No_code_integration {
  display: flex;
  flex-direction: column;
  text-align: left;
  left: 0;
  align-items: baseline;
} */


#operations-tag-No_code_integration button.expand-operation {
  display: none;
}

.markdown pre {
  /* background-color: white !important; */
  padding: 5px 10px;
  background: rgb(0 201 250 / 12%) !important;
}

.highlight {
  /* background-color: white !important; */
  background: rgb(0 201 250 / 12%) !important;
  list-style: none !important;
  padding: 5px 10px;
  font-size: 13px !important;
  margin-left: -40px;
}

h3#operations-tag-No_code_integration a {
  height: 40px;
  padding: 5px;
  background: rgb(0 201 250 / 12%) !important;
  width: 100%;
  border-radius: 5px;
}

#operations-tag-No_code_integration {
  display: flex;
  flex-direction: column;
  text-align: left;
  left: 0;
  align-items: baseline;
  margin-top: 15px;
}

a {
  color: #868e96;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  color: #000;
  background: #e969803d;
}

.header-tonggle {
  margin-right: 10px;
  margin-left: 10px;
}

.border {
  border: 1px solid black !important;
}

.border-lightsky {
  border: 1px solid darkturquoise !important;
  background-color: lightblue;
}

.lightsky {
  background-color: lightblue;
}

/* .border-lightsky {
  border: 1px solid darkturquoise;
} */

::-webkit-input-placeholder {
  /* Edge */
  color: #acb0b6b5 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #acb0b6b5 !important;
}

::placeholder {
  color: #acb0b6b5 !important;
}

.or.col-md-1 {
  margin-top: 2rem;
}

li.nav-item.search-box.d-none.d-md-block.margin-left-tongle {
  margin-left: 0px;
  /* width: 45vw; */
}

.marginLeft-minus-50 {
  margin-left: -50px;
}

/* button:focus {
  outline: 1px solid !important;
} */

img.screenshot-img-adv-setting-io {
  width: 75%;
  margin-top: -10px;
  /* margin: -40px 0 0 40px; */
}

.sample-screen-img {
  /* margin-top: -60px; */
  margin-top: -76px;
}

.setting-tooltip {
  padding: 1px 6px !important;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  font-style: italic;
}

span.acc-head {
  margin-left: 5px;
  /* display: flex;
	align-items: center; */
  font-size: 17px;
}

span #local {
  margin-top: 8px !important;
  margin-left: 0 !important;
}

img.modal-screenshot-img {
  width: 100%;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #00bcd4b5;
  border-color: #00bcd4b5;
  box-shadow: 0 1px 0 rgb(255 255 255 / 15%),
    0 0 0 0.2rem rgb(137 120 241 / 50%);
}

img.api-interaction-img {
  width: 90%;
  margin: 0 auto;
  display: block;
}

.api_div {
  display: block;
  flex-direction: row;

  width: 100%;
}

@media (max-width: 600px) {
  .api_div {
    flex-direction: column !important;
  }
}

@media (max-width: 767px) {
  .swagger-ui .opblock-tag small {
    flex: unset !important;
  }

  .swagger-ui .opblock .opblock-summary-operation-id,
  .swagger-ui .opblock .opblock-summary-path,
  .swagger-ui .opblock .opblock-summary-path__deprecated {
    /* margin-right: 125px; */
    word-break: unset !important;
  }

  .swagger-ui .opblock .opblock-summary-description {
    display: none;
  }

  .swagger-ui .opblock .opblock-summary-path {
    flex-shrink: 0;
    max-width: calc(100% - 71px - 1rem) !important;
  }

  .swagger-ui .opblock-tag {
    font-size: 16px;
  }

  .swagger-ui .markdown p,
  .swagger-ui .markdown pre,
  .swagger-ui .renderedMarkdown p,
  .swagger-ui .renderedMarkdown pre {
    margin: 1em auto;
    word-break: break-all;
    word-break: break-word;
    font-size: 8px !important;
  }

  .body-param {
    margin-left: -50px !important;
  }

  .swagger-ui .opblock .opblock-section-header {
    display: unset !important;
  }

  .swagger-ui .opblock .opblock-section-header {
    background: unset !important;
  }
}

a.sidebar-link.side-second-sub-menu {
  padding: 3px 5px 5px 30px !important;
}

ul.second-level {
  background-color: #dcf1f9 !important;
}

span.sidebar-link.has-arrow.side-sub-menu {
  padding: 12px 15px 12px 22px !important;
}

.side-sub-menu {
  padding: 12px 15px 12px 22px !important;
}

#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link:hover
  .feather,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link:hover
  .feather {
  color: #333;
}

span.hide-menu:hover {
  font-weight: 500;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link {
  padding: 7px 15px 7px 37px;
}

a.sidebar-link.side-second-sub-menu.active {
  font-weight: 500;
}

#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link {
  border-left: 3px solid #00bcd4;
  color: #00bcd4;
  background: rgba(0, 0, 0, 0.04);
  opacity: 1;
  font-weight: 500;
}

#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  i,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  .feather,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  i,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  .feather {
  color: #00bcd4;
}

#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .first-level
  .sidebar-item
  .sidebar-link.active,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .first-level
  .sidebar-item
  .sidebar-link.active {
  color: #00bcd4;
}

.icon-size {
  font-size: 22px;
  color: #54667ac4;
}

.btn-without-bg {
  font-weight: 400;
  border: none;
  background: transparent;
  color: #333;
  text-decoration: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00bcd4 !important;
  border-color: #00bcd4 !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #00cae5 !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #0292a5 !important;
}

.MuiLinearProgress-root {
  border-radius: 5px;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 2px 3px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.modal-header-search .modal-title {
  justify-content: -40px !important;
  margin-left: -40px !important;
  height: 100%;
  width: 100%;
}

/* b.logo-icon {
  margin-left: 35px;
} */

.margin-min-4 {
  margin-left: -65px;
  margin-top: -10px;
}

.swagger-ui .info {
  margin: 50px 0;
  display: none;
}

.swagger-ui .scheme-container {
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  margin: 0 0 20px;
  padding: 30px 0;
  display: none;
}

img.interaction-review-img {
  margin: 0 auto;
  display: block;
  width: 85%;
  

}

.api-refrence-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: #dcf1f9;
  color: white;
  text-align: center;
  padding: 10px;
  width: calc(100% - 240px);
  margin-left: 240px;
}

.swagger-api-ref {
  margin-bottom: 25px;
  margin-top: -60px;
}

.swagger-api-ref-main {
  margin-bottom: 25px;
}

p.no-code-para {
  font-size: 14px;
  line-height: 1.3;
}

a.nostyle.no-code-inte-api {
  border: 1px solid #00bcd4 !important;
  padding: 5px;
}

.basic-single-select{
  width: 100% !important;
}

.additionaL_filter .css-1hwfws3{
  padding:0px 0px  !important;
}
.additionaL_filter .css-16pqwjk-indicatorContainer{
  display:none !important;
}

.additionaL_filter .css-1wy0on6{
  display: none !important;
}


.iframe_table {
  border-collapse: collapse;
  /* width: 100%; */
  margin: 20px 0;
  width: -webkit-fill-available;
  font-size: 13px;
}

.iframe_table .iframe-thead  {
  border: 1px solid #070606 !important;
  padding: 8px !important;
  text-align: left;
  color: #000;
  
}

.iframe_table > th {
  background-color: #f2f2f2;
}
.iframe_table > tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-tooltip-reminder{
  max-width: 250px; /* Set the desired width */
  white-space: normal; /* Allow wrapping for long text */
  text-align: center; /* Center-align the text */
}

@media (min-width: 768px) {
  .col-md-3{
    flex: 0 0 20%;
  }
  .col-md-1{
    flex:0 0 7.33333%
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
    /* margin-left: 45px; */
    height: 60px;
  }

  .api-refrence-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #dcf1f9;
    color: white;
    text-align: center;
    padding: 10px;
    width: calc(100% - 240px);
    margin-left: 240px;
  }
}

.margin-min-alert {
  margin-bottom: -55px;
  position: absolute;
  width: 210px;
  /* top:-60px; */
  /* z-index:4; */
}

@media (min-width: 375px) and (max-width: 768px) {
  .margin-min-alert {
    margin-bottom: -55px;
    position: absolute;
    width: 210px;
    /* top:-60px; */
    z-index: 2;
  }
}

@media (max-width: 767px) {
  .api-refrence-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #dcf1f9;
    color: white;
    text-align: center;
    padding: 10px;
    width: 100%;
    margin-left: 240px;
  }

  .swagger-api-ref {
    margin-bottom: 100px;
  }
}

.reactEasyCrop_Container {
  top: 15% !important;
  height: 50vh !important;
}

.img-crop-modal {
  padding-top: 60px;
  height: 70vh;
}

.crop-btn-img-crop {
  position: absolute;
  right: 15px;
  bottom: 8px;
  /* top: 10px; */
}

.upload-btn-img-crop {
  /* position: absolute; */
  /* right: 15px; */
  /* top: 10px; */
  margin-bottom: 0 !important;
}

.rem-btn-img-crop {
  /* position: absolute; */
  /* right: 15px; */
  /* top: 10px; */
  margin-bottom: 0 !important;
  /* background:rgb(170,186,205) */
}

.MuiSlider-root {
  width: 80% !important;
}

span.input-group-text {
  background-color: transparent;
  border: none;
}

button.btn.btn.btn-link.btn.btn-secondary {
  background-color: transparent;
  border: unset;
  color: #000;
}

.data-table-dropdown.dropdown-menu.show {
  top: -25px !important;
  left: -60px !important;
  height: fit-content !important;
  width: fit-content !important;
  background-color: white;

  /* height: 100%;
	width: 100%; */
}

.min-or {
  margin-top: -60px;
}

.view-light-box {
  padding: 15px 12px 7px 12px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 5px;
}

.web_head {
  background-color: #00bcd433;
  border-radius: 20px 20px 0px 0px;
  padding: 8px 30px;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: unset !important;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.react-pdf__Document {
  width: 100%;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 50%);
  background-color: #ffffff;
  border: solid 5px rgb(0 0 0 / 53%);
  margin: auto;
  margin-top: 16px;
  overflow: auto;
  height: 91vh;
}

.scrollbar-container {
  position: relative;
  height: 75%;
}

.pdf-view-nav-list {
  padding-left: 0;
  margin-bottom: 12px;
  list-style: none;
}

.list-pdf-view {
  border-radius: 4px;
  padding: 4px 8px;
  background-color: #0000000d;
  cursor: pointer;
}

.blue-color {
  color: rgb(0, 188, 212);
}

span.nostyle.no-code-inte-api {
  background: rgb(0 201 250 / 12%) !important;
  border-color: #00bcd4 !important;
  width: 100%;
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
}

.tooltip-inner {
  max-width: 500px;  /* Set your desired width here */
  width: 500px;      /* Ensure both max-width and width are set */
}

.icon-hand {
  cursor: pointer;
}

ul.billing-nickname-list {
  margin-left: -24px;
}

.cursor-pointer {
  cursor: pointer;
  /* height: 25px; */
  width: auto !important;
}

#cursor:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-top: 80px;
  border-left: 80px;
  width: 0;
}

#cursor-pointer {
  cursor: pointer;
  /* border-radius: 20px; */
}

.exampleCustomCheckbox5 {
  cursor: pointer;
}

.button-post {
  border-radius: 8px;
  background-color: darkturquoise;
  border: none;
  color: white;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
}

.tooltip-inner {
  max-width: 500px;  /* Set your desired width here */
  width: 500px;      /* Ensure both max-width and width are set */
}


.crmIntegrationButton {
  right: 0;
  cursor: pointer;
  position: absolute;
  margin-top: -17px;
  margin-right: 16px;
}

.h-90 {
  height: 90% !important;
}

.dirodatadictionary {
  color: black;
}

.dirodatadictionary:hover {
  color: black;
}

li.no-code-para {
  font-size: 14px;
}

ul.ul-list.no-code-para {
  margin-left: -20px;
}

ul.ul-list {
  margin-left: -25px;
}

.pdftext {
  overflow: overlay;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.alignment {
  padding-top: 1px;
}

.alignmentreviewpending {
  padding-top: 1px;
}

.alignmentapproved {
  /* display: flex;
	flex-wrap: wrap; */
  padding-top: 2px;
  /* text-align: center; */
}

span.right-json-button {
  right: 60px;
  position: absolute;
}

.swagger-ui table tbody tr td:first-of-type {
  min-width: 6em;
}

.p-4.certified-row.row {
  margin-top: -7% !important;
}

#tabid {
  /* margin-top:55px; */
  /* overflow-y:scroll; */

  position: relative;
  /* height: 30%;  */
}

#tabid.form-control {
  height: 200px;
}

div#field-keyword {
  width: 60%;
}

label#field-label {
  width: 40%;
}

.copy-button {
  min-width: max-content;
  border-radius: 5px;
}

.btn-sm {
  border: #0000;
  font-size: 0.84rem;
}

.btnjson {
  width: 50px;
  /* height: 22px; */
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #00bcd4;
  border: 1px solid #0000;
  padding: 0 !important;
  /* margin: -4px 12px -4px -6px; */
  margin-right: 10px;
  font-size: 12px;
  /* line-height: 1.2; */
  border-radius: 4px;
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 30%),
    0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */

  transition: all 0.3s ease 0s !important;
}

.swagger-ui table.model tr.property-row .star {
  color: red !important;
  font-size: large !important;
}

.btnjson:hover {
  /* box-shadow: none; */
  /* background: transparent; */
  /* color: #111; */
  border: 1px solid #0000;
  background: #00bcd4;
  /* box-shadow: 0px 6px 12px rgba(43, 43, 43, 0.253) !important; */
  box-shadow: 0px 3px 8px rgba(43, 43, 43, 0.35) !important;
}

.btnjson:focus {
  background: #00bcd4;
  color: white;
  border: 2px solid #00bcd4;
  outline: none !important;
  box-shadow: none;
}

.btnjson:focus:hover {
  background: transparent;
  color: #111;
  border: 2px solid #00bcd4;
}

.btn-select-org {
  float: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: smaller;
}

#operations-tag- {
  display: none;
}

.renderedMarkdown {
  margin-left: 20px !important;
}

.renderedMarkdown p {
  font-size: 12px !important;
  margin-top: 4px !important;
  margin-bottom: 10px !important;
}

.swagger-ui .opblock-tag > div {
  display: none;
}

.toggleTextActive {
  color: #fcaf10 !important;
}

.toggleTextInActive {
  font-weight: 100;
}
.toggleTextInActive:hover {
  color:  #54667a;
  font-weight: 500;
}

.toggleContainer1 {
  /* margin-right: 5px; */
  /* width: 130px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid #fcaf10; */
  border-radius: 4px;
  height: 28px;
  padding: 2px;
  padding-left: 0;
  padding-right: 0;
  /* box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important; */
    
}

.toggleContainer2 {
  /* margin-right: 5px; */
  /* width: 130px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgb(248, 248, 248); */
  /* border: 0.5px solid rgb(208, 208, 208); */
  border-radius: 4px;
  height: 28px;
  padding: 2px;
  padding-left: 0;
  padding-right: 0;
  /* box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important; */
}

.reload-btnn {
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 40%),
    0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  border: 0px solid #0000 !important;
  height: 28px !important;
}

 .reload-btnn:hover {
  box-shadow: 0px 6px 12px rgba(43, 43, 43, 0.213) !important;
  box-shadow: 0px 3px 8px rgba(43, 43, 43, 0.3) !important;
} 

.raised-btn-info {
  /* padding: 3px 12px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 28px !important;
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 40%),
    0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  border: 0px solid #0000 !important;
  background: #ffbc34 !important;
  color: rgb(51, 51, 51) !important;
  /* color: #ffbc34; */
  font-weight: 400 !important;
  font-size: 14px !important;
  transition: all 0.3s ease 0s !important;
}

.raised-btn-info:hover {
  /* box-shadow: 0px 6px 12px rgba(43, 43, 43, 0.233) !important; */
  box-shadow: 0px 3px 8px rgba(43, 43, 43, 0.35) !important;
  /* background: #ffbc34 !important; */
}

.raised-btn-success {
  width: 120px;
  /* padding: 6px 14px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* padding-top: 2px !important; */
  height: 28px;
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 40%),
    0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  border: 0px solid #0000 !important;
  background: rgb(0, 188, 212) !important;
  color: rgb(255, 255, 255) !important;
  /* color: #ffbc34; */
  font-weight: 400 !important;
  font-size: 14.5px !important;
  transition: all 0.3s ease 0s !important;
}

.raised-btn-success-outline {
  cursor: pointer;
  width: 110px;
  /* padding: 6px 14px !important; */
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  /* padding-top: 2px !important; */
  height: 28px !important;
  /* box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 40%), 0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important; */
  border: 1px solid rgb(0, 188, 212) !important;
  background: #0000 !important;
  color: rgb(0, 188, 212);
  /* color: #ffbc34; */
  font-weight: 400 !important;
  font-size: 14.5px !important;
  transition: all 0.3s ease 0s !important;
  border-radius: 5px;
}

.raised-btn-success:hover {
  /* box-shadow: 0px 6px 12px rgba(43, 43, 43, 0.253) !important; */
  box-shadow: 0px 4px 10px rgba(43, 43, 43, 0.38) !important;
  /* background: #ffbc34 !important; */
}

.raised-btn-success-outline:hover {
  /* box-shadow: 0px 6px 12px rgba(43, 43, 43, 0.253) !important; */
  background: rgb(0, 188, 212) !important;
  color: white !important;
  /* background: #ffbc34 !important; */
}

.raised-btn-orange {
  width: 120px;
  /* padding: 6px 14px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* padding-top: 2px !important; */
  height: 36px;
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 40%),
    0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  border: 0px solid #0000 !important;
  background: #ff5100ce !important;
  color: rgb(255, 255, 255) !important;
  /* color: #ffbc34; */
  font-weight: 400 !important;
  font-size: 14.5px !important;
  transition: all 0.3s ease 0s !important;
}

.raised-btn-orange:hover {
  /* box-shadow: 0px 6px 12px rgba(43, 43, 43, 0.253) !important; */
  box-shadow: 0px 4px 10px rgba(43, 43, 43, 0.38) !important;
  /* background: #ffbc34 !important; */
}

.raised-btn-danger {
  width: 120px;
  /* padding: 6px 14px !important; */
  /* height: 28px !important; */
  height: 28px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 40%),
    0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  border: 0px solid #0000 !important;
  /* background: #f62d52dd !important; */
  background: #201f1fdd !important;
  color: rgb(255, 255, 255) !important;
  /* color: #ffbc34; */
  font-weight: 400 !important;
  font-size: 14.5px !important;
  transition: all 0.3s ease 0s !important;
}

.raised-btn-danger:hover {
  /* box-shadow: 0px 6px 12px rgba(43, 43, 43, 0.253) !important; */
  box-shadow: 0px 4px 8px rgba(43, 43, 43, 0.38) !important;
  /* background: #ffbc34 !important; */
}

.raised-btn-red {
  width: 120px;
  /* padding: 6px 14px !important; */
  /* height: 28px !important; */
  height: 28px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 40%),
    0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  border: 0px solid #0000 !important;
  background: #f62d52dd !important;
  /* background: #f12a2ae7 !important; */
  color: rgb(255, 255, 255) !important;
  /* color: #ffbc34; */
  font-weight: 400 !important;
  font-size: 14.5px !important;
  transition: all 0.3s ease 0s !important;
}

.raised-btn-red:hover {
  /* box-shadow: 0px 6px 12px rgba(43, 43, 43, 0.253) !important; */
  box-shadow: 0px 4px 8px rgba(43, 43, 43, 0.38) !important;
  /* background: #ffbc34 !important; */
}

.raised-mode-toggle {
  transition: all 0.3s ease 0s !important;
}

/* .raised-mode-toggle:hover {
  box-shadow: 0px 3px 8px rgba(43, 43, 43, 0.28) !important;
} */

.raised-search-bar {
  transition: all 0.3s ease 0s !important;
}

.raised-search-bar:hover {
  box-shadow: 0px 3px 8px rgba(43, 43, 43, 0.253) !important;
}

.shadow-box {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1),
    0 1px 1px rgba(0, 0, 0, 0.12) !important;
  border-radius: 5px !important;
}

.highlight-bg {
  background-color: #e7e7e7;
  padding: 0 5px;
  border-radius: 3px;
  color: #333;
}

.table th,
table td {
  position: relative;
}

.Dragger {
  cursor: col-resize;
  height: 100%;
  position: absolute;
  right: -4px;
  top: 0;
  width: 8px;
  z-index: 1;
}

th:last-child .Dragger {
  right: 0;
}

.type-icon {
  height: 18px;
  margin-right: 10px;
  margin-left: 5px;
}

/* .disablePdfView {
	pointer-events: none;
} */
.trackidClass {
  /* max-width: calc(20%) !important; */
  max-width: 150px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /* display: inline-block; for inline elements e.g. span, strong, em etc */
}

.trackidClass2 {
  /* max-width: calc(20%) !important; */
  max-width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /* display: inline-block; for inline elements e.g. span, strong, em etc */
}

/* .disablePdfView {
	pointer-events: none;
} */

.cursor-pointer {
  cursor: pointer;
}

.seperator_or {
  text-align: center;
  position: relative;
  max-width: calc(100% - 70px);
  margin: 20px auto 0;
  left: -15px;
}

.seperator_or:before,
.seperator_or:after {
  content: "";
  width: calc(50% - 20px);
  position: absolute;
  left: 0;
  top: 50%;
  border-top: solid 1px rgb(183 218 223);
}

.seperator_or:after {
  left: auto;
  right: 0;
}

optgroup {
  font-size: 14px;
}

optgroup option {
  font-size: 16px;
  text-indent: 1px;
}

.dropDnList button.custom-select {
  border: 1px solid #e9ecef;
  text-align: left;
  cursor: auto;
  color: rgb(84, 102, 122);
}

.dropDnList button.custom-select:hover,
.dropDnList button.custom-select:focus,
.dropDnList button.custom-select:active {
  background-color: transparent !important;
  color: rgb(84, 102, 122) !important;
  border: 1px solid #e9ecef;
  outline: none !important;
}

.dropDnList button.custom-select:focus {
  border-color: rgba(0, 0, 0, 0.25);
}

.dropDnList .dropdown-menu.show {
  width: 100%;
  transform: none !important;
  top: 40px !important;
}

.dropDnList .dropdown-item {
  padding: 0.35rem 1rem;
  margin: 0.15rem 0;
}

.dropDwnImg {
  width: 26px;
  margin-right: 8px;
}

span > .dropDwnImg {
  width: 32px;
  margin-right: 12px;
}

.countrySearch {
  width: calc(100% - 28px);
  margin: 2px 14px 4px;
}

.cust_select_overflow {
  max-height: 176px;
  overflow: auto;
}

.dr_lick_nickname {
  display: block;
  font-size: 16px;
  color: #000;
}

.dr_lick {
  display: block;
  font-size: 11px;
}

.links_loader {
  background-color: #eee;
  border-radius: 3px;
  height: 38px;
  margin: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.links_loader:before {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&0+0,0.5+50,0+100 */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */
  animation: skeleton 2.5s infinite;
  -webkit-animation: skeleton 2.5s infinite;
  content: "";
  width: 80px;
  height: 100%;
  position: absolute;
  left: -20%;
  top: 0;
}

@keyframes skeleton {
  0% {
    left: -20%;
  }

  100% {
    left: 150%;
  }
}

@-webkit-keyframes skeleton {
  0% {
    left: -20%;
  }

  100% {
    left: 150%;
  }
}

.cant_find_provider {
  border-top: solid 1px #eee;
  font-size: 14px;
  display: block;
  max-width: 100%;
  white-space: break-spaces;
  padding: 12px 0;
}

.cant_find_provider > span {
  color: #000;
  display: block;
}

.cant_find_provider + div.d-flex {
  padding: 0 0 8px;
}

.maxUrlWidth,
.dr_lick {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dr_wrapper {
  max-width: calc(100% - 40px);
}

.download_link {
  color: #2c2b2e;
}

.download_link:hover {
  color: #000000;
}

.border-table {
  border: 1px solid #e8eef3;
  border-radius: 10px;
  overflow: hidden;
}

.border-table .table tr:first-child td,
.border-table .table tbody tr:first-child td {
  border-top: none;
}

.border-table .table th:first-child,
.border-table .table td:first-child {
  padding-left: 2rem;
}

.border-table .table th,
.border-table .table td {
  font-family: sans-serif;
}

.diro-text-color {
  /* color: #0c0c0c; */
  color: #00bcd4;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  list-style: none;
}

.card .card-title {
  font-size: 15px !important;
}

.badge-primary {
  background-color: #02bcd4;
}

.text-end {
  text-align: right;
  margin-top: -15px;
}

img.email-img {
  width: 50%;
  padding-top: 10px;
}

/*swagger none*/
h3#operations-tag-default {
  display: none;
}

h3#operations-tag-\\\. {
  display: none;
} 

/* ============= nocode page*/
.doc_table {
  position: relative;
  display: flex;
  justify-content: center;
  width: 90%;
  margin-left: 60px;
  margin-top: 30px;
  box-shadow: 0 5px 8px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.verif_step {
  vertical-align: top !important;
  margin: 0%;
}

.tb_text_align {
  vertical-align: top !important;
  margin: 0%;
  cursor: pointer;
}

.tb_text_align:hover {
  background-color: rgb(222, 222, 224);
}


.li_text_position {
  padding-top: 24px
}

/* .li_delete_position {
  padding-top: 100px;
} */

.modalstyle .modal-content {
  background-color: hsl(188, 68%, 91%) !important;
  width: 550px;
  height: 480px;
  overflow: scroll;
  border-radius: 10px;
  top: 50px;
  margin-top: 59px;
  margin-left: 295px;
  box-shadow: 0 5px 8px 6px rgba(39, 38, 38, 0.1);
  overflow-x: hidden;
  scrollbar-width: 10px;
  border: 2px solid rgb(199, 230, 231) !important;

  &::-webkit-scrollbar {
    background-color: #c2bdbd;
    width: 5px;
    z-index: 3;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(99, 192, 204);
    border-radius: 100px;
  } 
}
/* &::-webkit-scrollbar {
      background-color: #c2bdbd;
      width: 5px;
      z-index: 3;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(99, 192, 204);
      border-radius: 100px;
    }  */
/* }


/* } */


.cancel_modal_icon {
  position: fixed;
  width: 530px;
  background-color: hsl(188, 68%, 91%);
  overflow: hidden;
  border-radius: 4px;
  z-index: 2;
   margin-top: -16px;
  margin-left: -10px;
  cursor: pointer;
  padding-right: 13px; 
}

@media screen and (min-resolution: 1.1dppx) {
  .modalstyle .modal-content {
    background-color: hsl(188, 68%, 91%) !important;
    width: 540px;
    height: 430px;
    overflow: scroll;
    border-radius: 10px;
    top: 50px;
    margin-top: 59px;
    margin-left: 260px;
    box-shadow: 0 5px 8px 6px rgba(39, 38, 38, 0.1);
    overflow-x: hidden;
    scrollbar-width: 10px;
    border: 2px solid rgb(199, 230, 231) !important;
  }
  .modalstyle.cancel_modal_icon {
    position: fixed;
    width: 500px;
    background-color: hsl(188, 68%, 91%);
    overflow: hidden;
    border-radius: 4px;
    /* z-index: 2; */
    margin-top: -16px;
    margin-left: -5px;
    cursor: pointer;
    padding-right: 5px;
  }
}


@media screen and (min-width: 790px) and (max-width: 1170px) {
  .modalstyle .modal-content {
    background-color: hsl(188, 68%, 91%) !important;
    width: 540px;
    height: 390px;
    overflow: scroll;
    border-radius: 10px;
    /* top: 50px; */
    margin-top: 59px;
    margin-left: 180px;
    box-shadow: 0 5px 8px 6px rgba(39, 38, 38, 0.1);
    overflow-x: hidden;
    scrollbar-width: 10px;
    border: 2px solid rgb(199, 230, 231) !important;
  }
  
  .modalstyle.cancel_modal_icon {
    position: fixed;
    width: 500px;
    background-color: hsl(188, 68%, 91%);
    overflow: hidden;
    border-radius: 4px;
    /* z-index: 2; */
    margin-top: -16px;
    margin-left: -5px;
    cursor: pointer;
    padding-right: 13px;
  }
  /* } */
}


@media screen and (min-resolution: 1.1dppx) {
  .modalstyle {
    .modal-content {
      background-color: hsl(188, 68%, 91%) !important;
      width: 540px;
      height: 430px;
      overflow: scroll;
      border-radius: 10px;
      top: 50px;
      margin-top: 59px;
      margin-left: 260px;
      box-shadow: 0 5px 8px 6px rgba(39, 38, 38, 0.1);
      overflow-x: hidden;
      scrollbar-width: 10px;
      border: 2px solid rgb(199, 230, 231) !important;

      .cancel_modal_icon {
        position: fixed;
        width: 515px;
        background-color: hsl(188, 68%, 91%);
        overflow: hidden;
        border-radius: 4px;
        /* z-index: 2; */
        margin-top: -16px;
        /* margin-left: -5px; */
        cursor: pointer;
        padding-right: 5px;
        

      }
    }
  }

}

@media screen and (min-width: 790px) and (max-width: 1170px) {
  .modalstyle {
    .modal-content {
      background-color: hsl(188, 68%, 91%) !important;
      width: 540px;
      height: 390px;
      overflow: scroll;
      border-radius: 10px;
      /* top: 50px; */
      margin-top: 59px;
      margin-left: 180px;
      box-shadow: 0 5px 8px 6px rgba(39, 38, 38, 0.1);
      overflow-x: hidden;
      scrollbar-width: 10px;
      border: 2px solid rgb(199, 230, 231) !important;

      .cancel_modal_icon {
        position: fixed;
        width: 500px;
        background-color: hsl(188, 68%, 91%);
        overflow: hidden;
        border-radius: 4px;
        /* z-index: 2; */
        margin-top: -16px;
        margin-left: -5px;
        cursor: pointer;
        padding-right: 13px;

      }
    }
  }

}

.modal-backdrop-blur {
  backdrop-filter: blur(0.2px) brightness(77%);
}

/* --------two row in single row */

.two_row {
  cursor: pointer;
  background: rgb(231, 234, 238);
}

.two_row:hover {
  background-color: rgb(222, 222, 224);
}

/* ---------------- */
.salesforce-row {
  display: flex;
  margin-bottom: 12px;
}

.salesforce-column {
  width: 47%;
  margin-right: 25px;
}

.salesforce-field-required {

  font-size: 13px;
  
}

h3#operations-tag-\\\. {
  display: none;
}

/* @priyanka */
.modalstyle {
  width: 60%; 
  max-width: none; 
 
 
}

.modalstyle-maximized {
  width: 70vw; 
  max-width: none; 
 

}

.modalbody {
  background-color: #d8f3f8;}
  .btn-secondary:hover,
.CloseModal:hover {
    background-color:none !important;
    /* border-color: #6c757d; */
}

  .CloseModal {
    font-size: 2rem;
    font-weight: 500;
    color: #343232;
    margin-top: -1.3rem;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  
  }
  
  .CloseModal:hover {
    box-shadow: none;
    text-decoration: none;
    
  }
  .CloseModal.btn-secondary:hover{
    background-color: none !important;
  }
  .CloseModal:focus {
    box-shadow: none;
  }
  
  .CloseModal:active {
    box-shadow: none;
  }
  
  .modalstyle-maximized .modalbody {
    overflow-y: scroll; 
    height: calc(96vh - 20px); 
    border-radius: 10px
  }
 
.list-group-item-action:focus {
  outline: none !important; /* !important to ensure override */
}

  /* @priyanka */

/* =======================transaction swagger api btn css========= */
 #operations-default-extractTransaction > div.no-margin > div > div.opblock-section > div.opblock-section-header > div.try-out {
    pointer-events: none;
    position: relative;
  }


  
 #operations-default-extractTransaction > div.no-margin > div > div.opblock-section > div.opblock-section-header > div.try-out::after {
    content: " Coming soon\2192";
    position: absolute;
    top: calc(100% + -30px); /* Position below the button with a small gap */
    left: -40%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    /* z-index: 1; */
  /* margin-left: 5px; */
}

/* =======================transaction swagger api btn css========= */
#operations-default-extractTransaction > div.no-margin > div > div.opblock-section > div.opblock-section-header > div.try-out {
  pointer-events: none;
  position: relative;
}



#operations-default-extractTransaction > div.no-margin > div > div.opblock-section > div.opblock-section-header > div.try-out::after {
  content: " Coming soon\2192";
  position: absolute;
  top: calc(100% + -30px); /* Position below the button with a small gap */
  left: -40%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  /* z-index: 1; */
/* margin-left: 5px; */
}
/* =============== email page send-otp */
.send-otp-text{
 cursor: pointer;
 font-weight: 500;
 color: #4d6cf5;
 font-size: 14px;
}
.form-check-input{
 cursor: pointer;
}

.send-otp-link:hover{
  text-decoration: underline;
}


.dropdown-toggle::after{
  margin-top: 0.455rem;
}

.swagger-ui .opblock-description-wrapper h4, .swagger-ui .opblock-external-docs-wrapper h4, .swagger-ui .opblock-title_normal h4 {
  color: #3b4151;
  font-family: sans-serif;
  font-size: 17px !important;
  margin: 0 0 5px;
}
/* -----------privacyscreen  */
.headingPrivacy{
  margin-bottom: 20px;
 width: 103%;
}
.privacytext_cls{
  display: flex;
  justify-content: right;
  font-size: small;
  font-weight: 400; 
  margin-right: 0.5rem;
}
.privacyheadingtext_cls{
  display: flex;
  justify-content: end;
  font-size: small;
  font-weight: 400; 
  margin-right: 0.5rem;
}
.privacytext_input{
  width: 80%;
}
.swagger-ui .opblock-description-wrapper h4, .swagger-ui .opblock-external-docs-wrapper h4, .swagger-ui .opblock-title_normal h4 {
  color: #3b4151;
  font-family: sans-serif;
  font-size: 17px !important;
  margin: 0 0 5px;
}

/* ===================set width for a specific class  for swagger 200 response ========*/
#operations-default-download_document > div.no-margin > div > div.responses-wrapper > div.responses-inner > div > div > table > tbody > tr > td.response-col_description > div:nth-child(1) > div > pre{
  width: 68vw!important;
}

#operations-default-orginaldocuments > div.no-margin > div > div.responses-wrapper > div.responses-inner > div > div > table > tbody > tr > td.response-col_description > div:nth-child(1) > div > pre{
  width: 68vw!important;

}

@media (max-width: 768px) {
  .response-col_description > div:nth-child(1) > div > pre {
    width: 90vw !important; /* Adjust the width for smaller screens */
  }
}.swagger-ui .opblock-description-wrapper h4{
  font-size: 14px;
}
/* ========= */
.swagger-ui .opblock-description-wrapper h4, .swagger-ui .opblock-external-docs-wrapper h4, .swagger-ui .opblock-title_normal h4 {
  color: #3b4151;
  font-family: sans-serif;
  font-size: 17px !important;
  margin: 0 0 5px;
}


/* -----------privacyscreen  */
.headingPrivacy{
  margin-bottom: 20px;
 width: 103%;
}
.privacytext_cls{
  display: flex;
  justify-content: right;
  font-size: small;
  font-weight: 400; 
  margin-right: 0.5rem;
}
.privacyheadingtext_cls{
  display: flex;
  justify-content: end;
  font-size: small;
  font-weight: 400; 
  margin-right: 0.5rem;
}
.privacytext_input{
  width: 80%;
}

/* ================ */
.modal-header-custom {
     background: rgb(255, 188, 52);
     display: flex;
    align-items: center;
  }


.widget_ul{
  margin-bottom: 0px !important;
}
.widget_ul > p{
  font-size: 14px !important;
  margin-bottom: 1px;
  position: relative;
  font-weight: 500;
}


.widget_ul > li {
  list-style-type: disc;
  margin-bottom: 1px;
  font-size: 13px;
  margin-left: 2rem;
  /* color: #000; */
}

.widget_span{
    margin-left: 1.2rem;
    font-size: 16px;
    font-weight: 500;
    font-family: sans-serif;
    color: #54667A;
}
/* ==========added css for widget mode srcipt code ======== */
.paracodeherence{
font-size: 13px;
}

.widgetcode_box {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  padding: 10px;
  width: 80%;
  border-radius: 5px;
  font-family: monospace;
  margin-top: 0px;
  margin-bottom: 0px;

}

.widgetcode > .green_text{
  color: #4caf50 !important;
}

.widgetcode > .blue_text{
  color: #2106b9 !important;
}
.widgetcode{
  margin-bottom: 0px !important;

}
.widget_li{

  display: inline;
  background: #f4f0f0;
  padding: 4px;
  border-radius: 3px;
  margin-left: 3px;
}
/* =============== cross icon ======= */
.alert-dismissible .close{
  top: -7px;
}




.table-responsive-custom {
  overflow-x: auto; /* Enables horizontal scrolling on smaller screens */
}

.table-custom th, .table-custom td {
  word-wrap: break-word; /* Wraps long words to prevent overflow */
  white-space: normal; /* Allows text to wrap to the next line */
  text-align: center; /* Centers text in cells */
  vertical-align: middle; /* Aligns content vertically in the middle */
  max-width: 100%; /* Ensures content fits within the cell */
  padding: 8px; /* Adds padding for better spacing */
  overflow: hidden; /* Prevents overflow */
}

.card-custom {
  overflow: hidden; /* Ensures content doesn't spill out of the card */
}

.modal-body-custom {
  padding: 15px; /* Provides padding for spacing within the modal */
}

@media (max-width: 768px) {
  .table-custom th, .table-custom td {
    font-size: 12px; /* Smaller font size for small screens */
    padding: 8px; /* Reduces padding for better fit on small screens */
  }
}

/* ========== */
.parameter__empty_value_toggle{
  display: none !important;
}
.btn.try-out__btn.reset{
  margin-left: 5px !important;

}


.custom-dropdown-menu.dropdown-menu.show{
  transform: translate3d(-121px,29px, 20px) !important;

}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8) !important; /* Set the opacity and color */
}

#widget_customize_option{
  font-weight: 400 !important;
  font-size: 12px  !important;
}

/* ===============
 */
 .input-with-icon {
  position: relative;
}

.input-with-icon i {
  position: absolute;
  left: 95%; /* Adjust this based on your design */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #6c757d; /* Adjust the icon color if needed */
}
.input-with-icon-click {
  left: 91% !important;
  top: 32% !important;
}
@media screen and (max-width: 1170px) {
  .input-with-icon-click  {
    position: absolute;
    left: 86% !important;
    top: 32% ;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d; /* Adjust the icon color if needed */
  }
  
  }
#main-wrapper > div > div > div > form > div > div.tab-pane.active > div > div > div > div.mt-2.mb-4.shadow-box.accordion-item > h2 > button > span > span.ml-1.mr-2.mb-4.mt-2 > i{
  margin-top: inherit;
}

/* ==============swagger heading === */
h3#operations-tag-Capture_process , h3#operations-tag-Smart_upload ,h3#operations-tag-After_download ,h3#operations-tag-Delete_documents
{
  pointer-events: none;
}
.expand-operation{
  display: none;
}


.icon-wrapper {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the position */
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.remove-icon {
  margin-right: 5px; /* Add some space between the two icons */
  cursor: pointer;
}

.error-icon {
  color: red; /* or any color for error */
}

/* ==============test mode icon animation==== */
/* Variables */
:root {
  --green: #4cc93f;
  --icon-base-size: 20px;
}

/* Structure for the success icon */
.success-icon {
  display: inline-block;
  width: 8em;
  height: 8em;
  font-size: var(--icon-base-size);
  border-radius: 50%;
  border: 4px solid rgba(76, 201, 63, 0.2); /* lighten($green, 20%) */
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);
}

/* Tooltip container */
.react-tooltip1 {
  max-width: 200px; /* Adjust the max-width as needed */
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words */
  text-align: center; /* Center align the text */
}

/* Elements inside the success icon */
.success-icon__tip,
.success-icon__long {
  display: block;
  position: absolute;
  height: 4px;
  background-color: rgba(76, 201, 63, 0.2); /* lighten($green, 20%) */
  border-radius: 10px;
  visibility: hidden; /* Initially hidden for animation */
}

.success-icon__tip {
  width: 2.4em;
  top: 4.3em;
  /* left: 1.4em; */
  transform: rotate(45deg);
  animation: tipInPlace 300ms ease-in-out forwards 180ms; /* Animation for tip */
}

.success-icon__long {
  width: 4em;
  transform: rotate(-45deg);
  top: 3.7em;
  left: 2.75em;
  animation: longInPlace 140ms ease-in-out forwards 440ms; /* Animation for long part */
}

/* Keyframes for animations */
@keyframes showSuccess {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }
  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible; /* Show tip */
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible; /* Show long part */
  }
}

/* Media query for responsive design */
@media (max-width: 600px) {
  .success-icon {
    width: 6em; /* Adjust size for smaller screens */
    height: 6em;
    font-size: 16px; /* Adjust font size accordingly */
  }
}

.modal-zoom-in {
  animation: zoomIn 0.5s forwards;
}

.modal-zoom-out {
  animation: zoomOut 0.5s forwards;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}


.sweetalert-zoom-in {
  animation: zoomIn 0.5s forwards;
}

.sweetalert-zoom-out {
  animation: zoomOut 0.5s forwards;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
/* =========url congifure ==== */
#main-wrapper > div > div > div > form > div > div.tab-pane.active > div:nth-child(5) > div > div > div > div > div > div > div{
  margin-bottom: 0px;
}
/* ========copy link tooltip=====  */
.react-tooltip1 {
  z-index: 1000; /* Ensure tooltip is above other elements */
  white-space: nowrap; /* Prevent text wrapping */
}
/* ======css for non-continuous tooltip ============= */

.non-continuous{
margin-bottom: 13px;
}

.horizonline{
      border-top: 1px solid #ccc;
      height: 10px;
      width: 100%;
      margin-left: 19px;
      margin-right: 15px;
      margin-top: 9px;    

}
/* ===============hmac tooltip css */
.setup-steps,
.verification-steps {
  padding-left: 20px;
}

.verification-result {
  padding-left: 40px;
}

p {
  margin-bottom: 10px;
}
.hmac_codebox{
  position: relative;
  
  margin: 18px;
}
.Hmac_texthover {
  color: #007BFF; /* Change this to your desired color */
  text-decoration: none; /* Remove underline if desired */
}

.Hmac_texthover:hover {
  color: #0056b3; /* Change color on hover */
  text-decoration: underline; /* Optional: add underline on hover */
}

.testemail_note{
  font-size: 12px;
  color: #6c757d;
  margin-top: 10px;
  display: flex;
}
/* =====request tab json btn css=========== */
.disabled-btn {
  pointer-events: auto !important;
  background-color: #838587 !important;
  color: white !important;
  border: none !important;
}

.disabled-btn:hover {
  /* pointer-events: none !important; */
  background-color: #838587 !important;
  color: white !important;
  border: none !important;
}
/* ==== */
.privacy-policy-link {
  text-decoration: none;
  color: #009efb !important;
  font-weight: 500;
}

.privacy-policy-link:hover {
  text-decoration: underline;
  color: #0056b3;
}
/* ============widget ul li tag css========= */
.widget_ul_li_tag{
  margin-left: -24px;
}
.url-style {
  color: #0066cc; /* or any blue color you prefer */
  cursor: text;
}
/* ====transaction tooltip css======== */

.__react_component_tooltip.custom-tooltip-test1 {
  width: 300px !important;
  max-width: 300px !important;
  text-align: left !important;
  padding: 10px !important;
  line-height: 1.5 !important;
  white-space: none !important;
  font-size: 12px !important;
  text-align: justify !important;
}
